import React, { useEffect, useRef, useState } from 'react';
import { AllIconNamesVariantEnum } from '@tamo-marketplace/genie';

import {
  AvatarCircle,
  ChevronIcon,
  DropdownMenu,
  DropdownMenuContent,
  Initials,
  StyledButton,
  UserContainer,
} from './desktopMenu.styles';

export const getInitials = (name: string | null) => {
  if (!name) {
    return '';
  }

  const names = name.split(/_| /);
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export type Props = {
  username: string | null;
  handleLogout: () => void;
};

const UserInitials = ({ username, handleLogout }: Props) => {
  const userInitials = getInitials(username);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const userContainer = useRef(null);

  useEffect(() => {
    const event = (ev) => {
      if (userContainer.current && userContainer.current.contains(ev.target)) {
        setIsMenuOpened(!isMenuOpened);
      } else {
        setIsMenuOpened(false);
      }
    };

    window.removeEventListener('click', event);
    window.addEventListener('click', event);

    return () => {
      window.removeEventListener('click', event);
    };
  }, [isMenuOpened]);

  return (
    <>
      <AvatarCircle>
        <Initials>{userInitials}</Initials>
      </AvatarCircle>
      <DropdownMenu>
        <UserContainer
          ref={userContainer}
          onClick={() => setIsMenuOpened(isMenuOpened)}
        >
          {username || 'Unknown'}
          <ChevronIcon
            name={
              isMenuOpened
                ? AllIconNamesVariantEnum.CHEVRON_UP
                : AllIconNamesVariantEnum.CHEVRON_DOWN
            }
          />
        </UserContainer>
        <DropdownMenuContent
          className="dropdown-content"
          style={{ display: isMenuOpened ? 'block' : 'none' }}
        >
          <StyledButton
            onClick={() => handleLogout()}
            data-testid="nav-button-logout"
          >
            Signout
          </StyledButton>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default React.memo(UserInitials);
