import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/products/imports`;

const MOCK_RESPONSE = {
  'content': [
    {
      'id': 575,
      'filename': 'jack_test.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 2,
      'brand_manager_id': 6,
      'created_at': '2022-04-05T16:43:31.578Z',
      'updated_at': '2022-04-05T16:43:58.717Z',
      'error_count': 0,
    },
    {
      'id': 572,
      'filename': 'jac_test.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 1,
      'brand_manager_id': 6,
      'created_at': '2022-03-30T16:13:04.214Z',
      'updated_at': '2022-03-30T16:13:27.325Z',
      'error_count': 0,
    },
    {
      'id': 549,
      'filename': 'JAC_products-20211008161342168.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 4,
      'brand_manager_id': 6,
      'created_at': '2022-02-09T10:13:48.053Z',
      'updated_at': '2022-02-09T10:13:49.772Z',
      'error_count': 4,
    },
    {
      'id': 548,
      'filename': 'test_images_jac.csv',
      'status': 'ERROR',
      'brand': 'Jack Wills',
      'lines': 0,
      'brand_manager_id': 6,
      'created_at': '2022-02-09T10:09:15.943Z',
      'updated_at': '2022-02-09T10:09:17.217Z',
      'error_count': 0,
    },
  ],
  'pageable': {
    'sort': {
      'empty': false,
      'unsorted': false,
      'sorted': true,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 4,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': false,
    'unsorted': false,
    'sorted': true,
  },
  'numberOfElements': 4,
  'first': true,
  'empty': false,
};

export const productsImportsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
