import React from 'react';
import { Image } from 'rebass/styled-components';
import { useRouter } from 'next/router';
import {
  HomeLink,
  MenuBox,
  MenuItem,
  MenuItemWrapper,
  MenuNavigationContent,
  MenuWrapper,
  Separator,
  StyledTitle,
} from './desktopMenu.styles';
import FirstLevelMenu from './FirstLevelMenu';
import UserInitials from './UserInitials';
import { HomeRoute, ImageRoute, VendorRoutes } from 'constants/routes';
import { HEADER_TITLE, ROLE_BRAND_ACQUISITION } from 'constants/common';

export type MenuProps = {
  handleLogout: () => void;
  hasRole: (role: string) => boolean;
  username: string | null;
};

const DesktopMenu = ({ handleLogout, hasRole, username }: MenuProps) => {
  const { asPath: currentPath } = useRouter();

  const topMenuRef = hasRole(ROLE_BRAND_ACQUISITION)
    ? VendorRoutes.basePath
    : HomeRoute;

  return (
    <>
      <MenuWrapper>
        <MenuBox>
          <MenuNavigationContent>
            <MenuItem>
              <MenuItemWrapper>
                <HomeLink
                  href={topMenuRef}
                  title="Go to home page"
                  data-testid="nav-button-home"
                >
                  <Image
                    className="header-logo"
                    src={ImageRoute('tamanna-logo-icon.svg')}
                  />
                  <StyledTitle className="header-title">
                    {HEADER_TITLE}
                  </StyledTitle>
                </HomeLink>
                <Separator />
              </MenuItemWrapper>
            </MenuItem>
            <MenuItem justifyContent="flex-start" flex={1}>
              <MenuItemWrapper>
                <FirstLevelMenu currentPath={currentPath} hasRole={hasRole} />
              </MenuItemWrapper>
            </MenuItem>
            <MenuItem>
              <MenuItemWrapper>
                <Separator />
                <UserInitials username={username} handleLogout={handleLogout} />
                <Separator />
              </MenuItemWrapper>
            </MenuItem>
          </MenuNavigationContent>
        </MenuBox>
      </MenuWrapper>
    </>
  );
};

export default DesktopMenu;
