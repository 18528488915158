import { useEffect, useState } from 'react';
import { isAndroid, isIPad13, isIPhone13, isMobileSafari, isTablet, isWinPhone } from 'react-device-detect';
import { screens } from 'styles/theme';

const checkMedia = (query) => typeof window !== 'undefined' && window.matchMedia && window.matchMedia(query).matches;

function isTouchDevice() {
  if (typeof window === 'undefined') return false;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if ('ontouchstart' in window || (window?.DocumentTouch && document instanceof DocumentTouch)) return true;
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  // include the 'heartz' - https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return checkMedia(query);
}

export default function useIsTouchDevice(): boolean {
  const [isTouch, setIsTouch] = useState(false);
  const checkAndSetIsTouch = (event?: Event) => {
    if (event) {
      const query = `(max-width: ${screens.sm})`;
      setIsTouch(checkMedia(query));
    } else {
      const touchable =
        isTouch || isAndroid || isIPad13 || isIPhone13 || isWinPhone || isMobileSafari || isTablet || isTouchDevice();
      setIsTouch(touchable);
    }
  };
  useEffect(() => {
    checkAndSetIsTouch();
    window.addEventListener('resize', checkAndSetIsTouch);
    return () => window.removeEventListener('resize', checkAndSetIsTouch);
  });

  return isTouch;
}
