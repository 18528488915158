import {
  loginHandler,
  productsAggregatorHandler,
  inventoryImportsHandler,
  productsImportsHandler,
  integrationsHandler,
  catalogsHandler,
  categoriesHandler,
  vendorIdHandler,
  vendorBrandsHandler,
  productVariantsHandler,
  productOverviewHandler,
} from './handlers';

const mswHandlers = [
  loginHandler,
  productsAggregatorHandler,
  inventoryImportsHandler,
  productsImportsHandler,
  integrationsHandler,
  catalogsHandler,
  categoriesHandler,
  vendorIdHandler,
  vendorBrandsHandler,
  productVariantsHandler,
  productOverviewHandler,
];

export default mswHandlers;
