import { LoginInfo, LoginResult } from 'models';
import fetcher from 'api/fetcher';

export const ENDPOINTS = {
  login: '/auth/signin',
};

export const login = async (data: LoginInfo) => {
  const { data: response } = await fetcher<LoginResult>(ENDPOINTS.login, {
    method: 'POST',
    data,
  });
  return response;
};
