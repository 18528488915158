import persist from 'mst-persist';
import { Instance, applySnapshot, types } from 'mobx-state-tree';
import Router from 'next/router';

import axios from 'axios';
import { cache } from 'swr';
import { Auth, initialState as authInitialState } from './auth';
import { ENDPOINTS } from 'api/services/authService';
import { instance as fetcherInstance } from 'api/fetcher';
import { LoginRoute } from 'constants/routes';
import { isSSR } from 'utils/next';
import { displayNotification } from 'components-shared/notifications';
import { generateContext } from 'utils/store';

const initialState = {
  auth: authInitialState,
};

const Store = types
  .model('Store', {
    auth: Auth,
  })
  .actions((self) => ({
    logout() {
      cache.clear();
      self.auth?.clearToken();
      applySnapshot(self, {
        ...initialState,
      });
    },
  }));

export type IStore = Instance<typeof Store>;
let store: IStore | undefined;

export const initialCleanStore = Store.create(initialState);

async function createStore(): Promise<IStore> {
  const store = initialCleanStore;

  // only store info on the browser locastorage since ssr dont have it
  if (!isSSR() && window.self === window.top) {
    await persist('seller-auth', store.auth);
    fetcherInstance.interceptors.request.use(
      async (config) => {
        if (isSSR()) return config;
        if (!store.auth.isLoggedIn && config.url != null && !config.url.includes(ENDPOINTS.login)) {
          store.logout();
          Router.push(LoginRoute);
          displayNotification('error', {
            message: 'Your session expired, please sign in again',
          });
          return {
            ...config,
            cancelToken: new axios.CancelToken((cancel) => cancel('Authentication token expired')),
          };
        }

        if (store.auth?.token) {
          config.headers.Authorization = `Bearer ${store.auth.token}`;
        } else if (config.headers.Authorization) {
          delete config.headers.Authorization;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }
  return store;
}

export async function initializeStore(snapshot = null): Promise<IStore> {
  const _store = store ?? (await createStore());
  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (snapshot) {
    applySnapshot(_store, snapshot);
  }
  // For SSG and SSR always create a new store
  if (isSSR()) return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return store;
}

export const { Provider, useStore } = generateContext<IStore>();
export { initialCleanStore as store };
