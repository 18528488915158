import React from 'react';
import { FirstLevelMenuContent, NavLink } from './desktopMenu.styles';
import { buildPathOptions, PathOptions } from './utils';
import { getKebabCase } from 'utils/primitives';

export type Props = {
  currentPath: string;
  hasRole: (role: string) => boolean;
  pathOptions?: PathOptions;
};

const FirstLevelMenu = ({
  currentPath,
  hasRole,
  pathOptions = buildPathOptions(hasRole),
}: Props) => {
  return (
    <FirstLevelMenuContent>
      {Object.entries(pathOptions).map(([menuName, entry], index) => {
        const activePath =
          currentPath === entry.basePath ||
          (entry.queryPath && currentPath.includes(entry.queryPath));

        return (
          <NavLink
            key={`menu-level1-item${index}`}
            data-testid={`nav-menu-${getKebabCase(menuName)}`}
            {...(activePath ? { className: 'active' } : {})}
            href={entry.basePath}
          >
            {menuName}
          </NavLink>
        );
      })}
    </FirstLevelMenuContent>
  );
};

export default FirstLevelMenu;
