import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

datadogRum.init({
  applicationId: publicRuntimeConfig.datadog.applicationId,
  clientToken: publicRuntimeConfig.datadog.clientToken,
  site: publicRuntimeConfig.datadog.site,
  service: publicRuntimeConfig.datadog.service,
  env: publicRuntimeConfig.datadog.env,
  version: publicRuntimeConfig.datadog.version,
  sampleRate: 100,
  trackInteractions: true,
});
