import React, { ReactElement } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { ToastContainer } from './container';

export type ErrorNotificationProps = {
  message: string;
};

export function ErrorNotification(props: ErrorNotificationProps): ReactElement {
  const { message } = props;
  return (
    <ToastContainer backgroundColor="red" icon="face_sad">
      <Flex justifyContent="center" alignItems="center" data-testid="toast-error">
        <Text>{message}</Text>
      </Flex>
    </ToastContainer>
  );
}
