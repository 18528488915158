import { createContext, useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateContext = <State extends any>(): {
  Provider: React.Provider<State>;
  useStore: () => State;
} => {
  const store = createContext<State>(undefined as State);
  const { Provider } = store;

  return {
    Provider: Provider,
    useStore: () => {
      const state = useContext(store);
      if (state === undefined) throw Error('Provider is not correctly implemented!');
      return state;
    },
  };
};
