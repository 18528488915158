/**
 * Utils that manipulate generic primitive values like strings
 */

import { Empty, Nullable } from 'models/utils';

export const isEmpty = (value: unknown): value is Empty => {
  if (value == null) return true;
  if (Array.isArray(value) && value.length === 0) return true;
  // eslint-disable-next-line @typescript-eslint/ban-types
  if (typeof value === 'object' && Object.keys(value as object).length === 0) return true;
  if (value === '' || value === 0 || value === '0' || value === false) return true;

  return false;
};

/**
 * Returns a comma sepparated string, with an 'or' at the end
 * @param values array of strings to join
 * @return joined string
 */
export const getNaturalCSVString = (...values: string[]): string => {
  const firstValues = [...values.filter((str) => !!str)];
  if (firstValues.length === 0) return '';
  if (firstValues.length === 1) return firstValues[0];
  const [lastValue] = firstValues.splice(-1, 1);
  return [firstValues.join(', '), lastValue].join(' or ');
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

/**
 * Transforms one or multiple strings into one kebab-case string 🥙
 *
 * Example: A CamelCase String => a-camel-case-string
 * @param value input string
 * @returns fresh kebab string
 */
export const getKebabCase = (...values: Nullable<unknown>[]): string => {
  return (
    values
      // trim whitespace
      .reduce<string[]>((acc, val) => (val != null && val !== '' ? [...acc, val?.toString().trim()] : acc), [])
      .join('-')
      .replace(/([a-z])([A-Z])/g, '$1-$2') // separate case changes by dashes (camelCase)
      .replace(/[_ ]/g, '-') // replace underscores and spaces by dashes
      .toLowerCase()
  );
};
