import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/v1/products-aggregator/MTk3MjM/variants`;

const MOCK_RESPONSE = {
  'content': [
    {
      'sku': 'ANY_VARIANT_SKU',
      'name': 'ANY_VARIANT_NAME',
      'price': {
        'value': 99.99,
        'sale': 0.0,
        'currency': 'KWD',
      },
      'inventory': {
        'quantityAvailable': 12,
        'quantityOnHands': 15,
        'quantityReserved': 2,
        'quantityBuffered': 1,
        'locationId': 'ANY_INVENTORY_LOCATION_ID',
      },
    },
    {
      'sku': 'ANY_VARIANT_SKU2',
      'name': 'ANY_VARIANT_NAME2',
      'price': {
        'value': 99.99,
        'sale': 0.0,
        'currency': 'KWD',
      },
      'inventory': {
        'quantityAvailable': 12,
        'quantityOnHands': 15,
        'quantityReserved': 2,
        'quantityBuffered': 1,
        'locationId': 'ANY_INVENTORY_LOCATION_ID',
      },
    },
  ],
  'pageable': {
    'sort': {
      'sorted': true,
      'unsorted': false,
      'empty': false,
    },
    'pageNumber': 0,
    'pageSize': 10,
    'offset': 0,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 1,
  'last': false,
  'first': true,
  'sort': {
    'sorted': true,
    'unsorted': false,
    'empty': false,
  },
  'numberOfElements': 1,
  'size': 1,
  'number': 0,
  'empty': false,
};

export const productVariantsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
