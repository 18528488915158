import React, { ReactElement } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { ToastContainer } from './container';

export type InfoNotificationProps = {
  message: string;
};

export function InfoNotification(props: InfoNotificationProps): ReactElement {
  const { message } = props;
  return (
    <ToastContainer backgroundColor="darkgrey">
      <Flex justifyContent="center" alignItems="center">
        <Text>{message}</Text>
      </Flex>
    </ToastContainer>
  );
}
