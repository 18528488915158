import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/inventory/imports`;

const MOCK_RESPONSE = {
  'content': [
    {
      'id': 254,
      'filename': 'JAC products_small_test.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 1,
      'brand_manager_id': 6,
      'created_at': '2022-03-30T16:17:04.245Z',
      'updated_at': '2022-03-30T16:17:04.957Z',
      'error_count': 0,
    },
    {
      'id': 253,
      'filename': 'JAC products_small.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 18,
      'brand_manager_id': 6,
      'created_at': '2022-03-30T15:48:18.249Z',
      'updated_at': '2022-03-30T15:48:19.550Z',
      'error_count': 0,
    },
    {
      'id': 228,
      'filename': 'JAC products.csv',
      'status': 'FINISHED',
      'brand': 'Jack Wills',
      'lines': 4608,
      'brand_manager_id': 6,
      'created_at': '2022-03-18T10:45:12.358Z',
      'updated_at': '2022-03-18T10:47:41.544Z',
      'error_count': 0,
    },
  ],
  'pageable': {
    'sort': {
      'empty': false,
      'unsorted': false,
      'sorted': true,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 3,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': false,
    'unsorted': false,
    'sorted': true,
  },
  'numberOfElements': 3,
  'first': true,
  'empty': false,
};

export const inventoryImportsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
