import { useRouter } from 'next/router';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import { HomeRoute, LoginRoute } from 'constants/routes';
import { useStore } from 'store';
import Spinner from 'components-shared/Spinner';
import { isSSR } from 'utils/next';
import { CustomLayoutProps } from 'models/next/screens';
type Props = {
  DefaultLayout: (props: CustomLayoutProps) => JSX.Element;
};

const LoadingScreen = () => (
  <Flex width="100%" height="100vh">
    <Spinner relative />
  </Flex>
);

const ProtectRoutes: React.FC<Props> = ({ children, DefaultLayout }) => {
  const { push: pushToRoute, asPath: pathName } = useRouter();
  const { auth } = useStore();

  const navigateToRoute = (route: string) => {
    if (isSSR()) return;
    pushToRoute(route);
  };

  if (!auth?.isLoggedIn) {
    if (pathName !== LoginRoute) {
      navigateToRoute(LoginRoute);
      return <LoadingScreen />;
    }

    return <React.Fragment>{children}</React.Fragment>;
  }

  if (pathName === LoginRoute) {
    navigateToRoute(HomeRoute);
    return <LoadingScreen />;
  }

  return <DefaultLayout>{children}</DefaultLayout>;
};

export default ProtectRoutes;
