import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    overflow: overlay; 
  }

  body { // for FireFox
    scrollbar-color: darkgray transparent;
    scrollbar-width: thin;
  }

  body::-webkit-scrollbar {
    width: 6px;
  }

  body::-webkit-scrollbar-track {
    background: transparent;
  }

  body::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 4px;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .Toastify { 
    &__close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff;
      opacity: 1;
    }

    &__toast {
      padding: 0;
      margin: 0;
      min-width: 0;
      min-height: 50px;
      border-radius: 10px;
      margin-top: 10px;
      direction: inherit !important;

      &:first-child {
        margin-top: 0;
      }


      &-container { 
        width: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;

        &--top-right{
          right:1em;
        }
      }

      &-body {
        margin: 0;
        padding: 0;
        display: flex;
        max-width: 600px;
        word-break: break-all;       
      }
    }
  }
`;

export const CenterFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const InputGroup = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const fadeInOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const BaseTableStyle = styled(Flex)(
  ({ theme: { colors } }) => css`
    flex-direction: column;
    width: 100%;
    table {
      border-spacing: 0;
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      tr.clickable {
        transition: background-color 0.3s ease;
        cursor: pointer;
        & :hover {
          cursor: pointer;
          background-color: ${colors.neutral40};
        }
      }

      th,
      td {
        text-align: left;
        margin: 0;
        padding: 16px;
        border-bottom: 1px solid ${colors.neutral100};

        :last-child {
          border-right: 0;
        }
        word-break: break-word;
      }

      th {
        border-bottom: 0;
        & :first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        & :last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  `
);
