import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfig from 'next/config';
import { HTTP_CODES } from 'constants/network';
import { Nullable } from 'models/utils';

const { publicRuntimeConfig } = getConfig();

const apiInstance = axios.create({
  baseURL: publicRuntimeConfig.apiURL,
});

const BASE_HEADERS = {
  Accept: 'application/json',
  'content-type': 'application/json;charset=UTF-8',
};

export type FetcherOptions = {
  addToContextRequest?: Record<string, string>;
  /**
   * Whether axios should automatically validate an error status code, by throwing an exception
   * If false, error codes should be manually validated
   */
  validateErrorCodes?: boolean;
  body?: Record<string, string>;
} & AxiosRequestConfig;

export type FetcherResponse<T> = {
  data: T;
  statusCode: number;
  headers: Record<string, string>;
};

const fetcher = async <T>(
  url: string,
  options?: Nullable<FetcherOptions>,
  instance?: Nullable<AxiosInstance>
): Promise<FetcherResponse<T>> => {
  const _instance = instance ?? apiInstance;
  const { validateErrorCodes = true } = options ?? {};

  const parsedOptions = {
    ...options,
    headers: {
      ...BASE_HEADERS,
      ...(options?.headers || {}),
    },
    timeout: 300000,
    validateStatus: function (status: number) {
      if (validateErrorCodes) return status >= HTTP_CODES.OK && status < HTTP_CODES.MULTIPLE_CHOICES;
      return status >= HTTP_CODES.OK && status < HTTP_CODES.INTERNAL_SERVER_ERROR;
    },
  };

  const request = _instance(url, parsedOptions);
  const res: AxiosResponse<T> = await request;

  return {
    data: res?.data,
    statusCode: res?.status,
    headers: res?.headers,
  };
};

export { apiInstance as instance };
export default fetcher;
