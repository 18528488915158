import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FlexProps } from 'rebass';
import ClientReactPortal from 'utils/reactPortal';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { CenterFlex } from 'styles';
import { StyleColor } from 'models/utils';
import { getStyleColorValue } from 'utils/color';

const DEFAULT_SIZE = 100;

const strokeAnimation = keyframes`
  from {
    stroke-dashoffset: 219;
    transform: rotateZ(0deg);
  }
  75% {
     stroke-dashoffset: 120;
  }
  to {
   stroke-dashoffset: 219;
    transform: rotateZ(360deg);
  }
`;

const Circle = styled.circle`
  stroke-width: 10;
  fill: transparent;
  stroke-linejoin: miter;
  stroke-linecap: round;
  fill-opacity: 0;
  stroke-miterlimit: 4;
`;

const CircleAnimated = styled(Circle)<Pick<SpinnerProps, 'color'>>(
  ({ color, theme: { colors } }) => css`
    opacity: 0.8;
    transition: 2s stroke-dashoffset 1s rotation;
    // axis compensation
    transform-origin: 50% 50%;
    animation-duration: 2s;
    animation-name: ${strokeAnimation};
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    stroke: var(
      --spinner-color,
      ${color ? getStyleColorValue(color) : colors.neutral120}
    );
    stroke-dasharray: 219.9114857512855 219.9114857512855;
    stroke-dashoffset: 160;
  `
);

type ContainerProps = {
  dim?: boolean;
  global?: boolean;
  relative?: boolean;
  isMobile: boolean;
};

const Container = styled(CenterFlex)<ContainerProps>(
  ({ global, dim, relative, isMobile, theme: { colors } }) => ({
    position: getPosition(global, relative),
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: isMobile && !dim ? 'fit-content' : '100%',
    height: '100%',
    ...(dim
      ? {
          position: 'absolute',
          backgroundColor: colors.neutral20,
          opacity: 0.5,
        }
      : {}),
  })
);

const ImageContainer = styled.div<Pick<SpinnerProps, 'size'>>(
  ({ size, theme: { zIndices } }) => css`
    z-index: ${zIndices.zIndex1 + 1};
    position: relative;
    overflow: hidden;
    width: ${size}px;
    min-width: ${size}px;
    max-width: ${size}px;
    height: ${size}px;
    min-height: ${size}px;
    max-height: ${size}px;

    & svg {
      vertical-align: top;
    }
  `
);

const getPosition = (global?: boolean, relative?: boolean) => {
  if (global) return 'fixed';
  if (relative) return 'relative';
  return 'absolute';
};

const SpinnerImg = ({
  size = DEFAULT_SIZE,
  color,
}: Pick<SpinnerProps, 'size' | 'color'>) => (
  <ImageContainer size={size}>
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`}
    >
      <Circle stroke="#AAAAAA" opacity="0.1" r="35" cx="50%" cy="50%" />
      <CircleAnimated color={color} r="35" cx="50%" cy="50%" />
    </svg>
  </ImageContainer>
);

type SpinnerProps = {
  relative?: boolean;
  size?: number | string;
  color?: StyleColor;
  title?: string;
} & Pick<ContainerProps, 'global' | 'dim'> &
  FlexProps;

const Spinner = (properties: SpinnerProps) => {
  const { global, dim, relative, size, color, title, ...props } = properties;
  const isMobile = useIsTouchDevice();

  const SpinnerElm = (
    <Container
      global={global}
      dim={dim}
      relative={relative}
      isMobile={isMobile}
      title={title}
      data-testid="spinner"
      {...props}
    >
      <SpinnerImg size={size} color={color} />
    </Container>
  );

  if (global)
    return <ClientReactPortal selector="body">{SpinnerElm}</ClientReactPortal>;

  return SpinnerElm;
};

export default Spinner;
