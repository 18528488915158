import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/products/categories`;

const MOCK_RESPONSE = {
  'content': [
    {
      'id': 388,
      'name': 'Pillow & Comforter Inserts',
      'categoryKey':
        'home_furnishing_and_accessories_bedding_pillow_and_comforter_inserts',
      'categoryId': '01F53Q4GE5HTWZ1GCMK7P0030F',
      'url':
        '/home-furnishing-and-accessories-bedding-pillow-and-comforter-inserts',
      'parentCategory': {
        'id': 384,
      },
    },
    {
      'id': 387,
      'name': 'Quilts & Blankets',
      'categoryKey':
        'home_furnishing_and_accessories_bedding_quilts_and_blankets',
      'categoryId': '01F53Q3VDEJPHA11STAANS1WNS',
      'url': '/home-furnishing-and-accessories-bedding-quilts-and-blankets',
      'parentCategory': {
        'id': 384,
      },
    },
    {
      'id': 378,
      'name': 'Candles and Room Fragrance',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance',
      'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
      'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
      'parentCategory': {
        'id': 358,
      },
    },
    {
      'id': 382,
      'name': 'Candle Holders',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance_candle_holders',
      'categoryId': '01F53EATVHJ7590XNP0S8Z0TFJ',
      'url':
        '/home-furnishing-and-accessories-candles-and-room-fragrance-candle-holders',
      'parentCategory': {
        'id': 378,
        'name': 'Candles and Room Fragrance',
        'categoryKey':
          'home_furnishing_and_accessories_candles_and_roomfragrance',
        'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
        'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
        'parentCategory': {
          'id': 358,
        },
      },
    },
    {
      'id': 379,
      'name': 'Candles',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance_candles',
      'categoryId': '01F53E8P3VPS751Y1ZX2JY0C3T',
      'url':
        '/home-furnishing-and-accessories-candles-and-room-fragrance-candles',
      'parentCategory': {
        'id': 378,
        'name': 'Candles and Room Fragrance',
        'categoryKey':
          'home_furnishing_and_accessories_candles_and_roomfragrance',
        'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
        'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
        'parentCategory': {
          'id': 358,
        },
      },
    },
    {
      'id': 380,
      'name': 'Diffusers',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance_diffusers',
      'categoryId': '01F53E9C5MVFVY07EJE55T0K6G',
      'url':
        '/home-furnishing-and-accessories-candles-and-room-fragrance-diffusers',
      'parentCategory': {
        'id': 378,
        'name': 'Candles and Room Fragrance',
        'categoryKey':
          'home_furnishing_and_accessories_candles_and_roomfragrance',
        'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
        'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
        'parentCategory': {
          'id': 358,
        },
      },
    },
    {
      'id': 383,
      'name': 'Fragrance Oils',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance_fragrance_oils',
      'categoryId': '01F53EBJ1W9JAK0E2J3J4Y1C8R',
      'url':
        '/home-furnishing-and-accessories-candles-and-room-fragrance-fragrance-oils',
      'parentCategory': {
        'id': 378,
        'name': 'Candles and Room Fragrance',
        'categoryKey':
          'home_furnishing_and_accessories_candles_and_roomfragrance',
        'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
        'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
        'parentCategory': {
          'id': 358,
        },
      },
    },
    {
      'id': 381,
      'name': 'Lanterns',
      'categoryKey':
        'home_furnishing_and_accessories_candles_and_roomfragrance_lanterns',
      'categoryId': '01F53EA38DJWXW0VFGN08Y1QJY',
      'url':
        '/home-furnishing-and-accessories-candles-and-room-fragrance-lanterns',
      'parentCategory': {
        'id': 378,
        'name': 'Candles and Room Fragrance',
        'categoryKey':
          'home_furnishing_and_accessories_candles_and_roomfragrance',
        'categoryId': '01F2RE4XPV0NZZ1Y35AWC50JW9',
        'url': '/home-furnishing-and-accessories-candles-and-room-fragrance',
        'parentCategory': {
          'id': 358,
        },
      },
    },
    {
      'id': 392,
      'name': 'Poufs',
      'categoryKey': 'home_furnishing_and_accessories_cushions_poufs',
      'categoryId': '01F53Q1WRN8ZSB03W4GEFH1PY6',
      'url': '/home-furnishing-and-accessories-cushions-poufs',
      'parentCategory': {
        'id': 390,
      },
    },
    {
      'id': 391,
      'name': 'Throws',
      'categoryKey': 'home_furnishing_and_accessories_cushions_throws',
      'categoryId': '01F53Q17MKXFKJ0CMETDS615Y7',
      'url': '/home-furnishing-and-accessories-cushions-throws',
      'parentCategory': {
        'id': 390,
      },
    },
  ],
  'pageable': {
    'sort': {
      'empty': true,
      'unsorted': true,
      'sorted': false,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 10,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': true,
    'unsorted': true,
    'sorted': false,
  },
  'numberOfElements': 10,
  'first': true,
  'empty': false,
};

export const categoriesHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
