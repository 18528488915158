import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/v1/products-aggregator`;

const MOCK_RESPONSE = {
  'content': [
    {
      'id': 'MTk3MjM',
      'externalId': '01FA12WZ5WXQWT0EJX1DK71TP8',
      'sku': '411391066500',
      'name': 'Pullborough 2 Pack Socks',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MzI',
      'externalId': '01FA12X6GK352E1YC7N4S814KS',
      'sku': '702386030000',
      'name': 'Elstree Backpack',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MTU',
      'externalId': '01FA12WS1ET1JB0YN6736P0BYA',
      'sku': '702388066500',
      'name': 'Leyland Gym Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MTk',
      'externalId': '01FA12WW7HFG5P1DFS6CYF1K0J',
      'sku': '702388909500',
      'name': 'Leyland Gym Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3Mjc',
      'externalId': '01FA12X251XWC916JT1VTW0FT2',
      'sku': '702434224100',
      'name': 'Brislington Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MTY',
      'externalId': '01FA12WSVZ7Z2T19A959MQ1KHT',
      'sku': '702453066900',
      'name': 'Eastleigh Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MjY',
      'externalId': '01FA12X1DRCX620YXK8AHN0014',
      'sku': '702453224600',
      'name': 'Eastleigh Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MjE',
      'externalId': '01FA12WXPV2D4V0WZX8HH30RHW',
      'sku': '702455926900',
      'name': 'Ambleshire Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MjU',
      'externalId': '01FA12X0PG160F02BJJF3807BW',
      'sku': '702465224100',
      'name': 'Elmswell Bag',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
    {
      'id': 'MTk3MjI',
      'externalId': '01FA12WYE7D1KG0H9ZWB7T0DMM',
      'sku': '702922066900',
      'name': 'Claremont Backpack',
      'brandName': 'Jack Wills',
      'numberOfVariants': 1,
    },
  ],
  'pageable': {
    'sort': {
      'empty': true,
      'sorted': false,
      'unsorted': true,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 10,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': true,
    'sorted': false,
    'unsorted': true,
  },
  'numberOfElements': 10,
  'first': true,
  'empty': false,
};

export const productsAggregatorHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
