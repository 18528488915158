import React from 'react';
import { ToastContainer } from 'react-toastify';
import {
  MainThemeProvider,
  useTheme,
} from '@tamannabit/design-system.foundations.context.main-theme';
import { ComponentWithCustomLayoutProps } from 'models/next/screens';
import { Provider as StoreProvider, store } from 'store';
import { Compose, PropsOf, ProviderType } from 'contexts/composer';
import { LastImportStateProvider } from 'contexts/lastImportState';
import CustomHead from 'layouts/customHead';
import { GlobalStyles } from 'styles';

export const AppContext: React.FC<{
  appProps: ComponentWithCustomLayoutProps;
}> = ({ children }) => {
  const { theme } = useTheme();
  const components: ProviderType[] = [
    [StoreProvider, { value: store } as PropsOf<typeof StoreProvider>],
    [MainThemeProvider, { theme } as PropsOf<typeof MainThemeProvider>],
    [
      LastImportStateProvider,
      { children } as PropsOf<typeof LastImportStateProvider>,
    ],
  ];
  const noChildComponents: ProviderType[] = [
    CustomHead,
    GlobalStyles,
    ToastContainer,
  ];

  return (
    <Compose components={components} noChildComponents={noChildComponents}>
      {children}
    </Compose>
  );
};
