import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/vendors/30/brands`;

const MOCK_RESPONSE = {
  'content': [
    {
      'id': 'd6a133cf-67e8-4a87-a396-4daf503343fc',
      'name': 'Egyptian Magic',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-egyptian_magic',
        'MEN': '/men-vavavoom-egyptian_magic',
      },
      'translation': 'إيجيبشن ماجيك',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 100.0,
      },
    },
    {
      'id': 'cf585720-1893-403f-bcf1-b1692e35ddfc',
      'name': 'Viseart',
      'verticals': ['WOMEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-viseart',
      },
      'translation': 'فيزارت',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '08870ac1-b5b8-46a0-ae21-7f3b41eed879',
      'name': 'Lootah',
      'verticals': ['WOMEN', 'MEN', 'HOME'],
      'urls': {
        'WOMEN': '/women-vavavoom-lootah',
        'MEN': '/men-vavavoom-lootah',
        'HOME': '/home-vavavoom-lootah',
      },
      'translation': 'لوتاه',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '5ee92119-ccd2-45a0-a4f0-cf048b8fed90',
      'name': 'Le Couvent',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-le_couvent',
        'MEN': '/men-vavavoom-le_couvent',
      },
      'translation': 'لو كوفينت',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '2b2333fb-5654-333e-a127-3d0fd38e27d1',
      'name': 'Goutal Paris',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-goutal_paris',
        'MEN': '/men-vavavoom-goutal_paris',
      },
      'translation': 'غوتال باريس',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '2ec68674-63cc-7905-2b1a-7ad54efec438',
      'name': 'Atkinsons',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-atkinsons',
        'MEN': '/men-vavavoom-atkinsons',
      },
      'translation': 'أتكينسونز',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '295f3876-e941-7871-dff3-6a1fb2403228',
      'name': 'Clarins',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-clarins',
        'MEN': '/men-vavavoom-clarins',
      },
      'translation': 'كلارنس',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': 'ccb8c2cc-a345-a3e8-4311-c6bc08b781f3',
      'name': 'Dunhill',
      'verticals': ['MEN'],
      'urls': {
        'MEN': '/men-vavavoom-dunhill',
      },
      'translation': 'دانهيل',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '3a3cf99d-ddb1-4584-055d-a8c000333496',
      'name': 'Floris',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-floris',
        'MEN': '/men-vavavoom-floris',
      },
      'translation': 'فلوريس',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
    {
      'id': '4dc414f1-71a7-bda8-c710-cba8ba8f7535',
      'name': 'Guru',
      'verticals': ['WOMEN', 'MEN'],
      'urls': {
        'WOMEN': '/women-vavavoom-guru',
        'MEN': '/men-vavavoom-guru',
      },
      'translation': 'جورو',
      'settings': {
        'minimumSafetyQuantityThreshold': 3,
        'percentageQuantityAvailability': 70.0,
      },
    },
  ],
  'pageable': {
    'sort': {
      'empty': true,
      'unsorted': true,
      'sorted': false,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 10,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': true,
    'unsorted': true,
    'sorted': false,
  },
  'numberOfElements': 10,
  'first': true,
  'empty': false,
};

export const vendorBrandsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
