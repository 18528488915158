import jwtDecode, { JwtPayload } from 'jwt-decode';

import { cast, flow, types } from 'mobx-state-tree';
import { LoginInfo, LoginResult } from 'models';
import { login } from 'api/services/authService';

export const initialState = {
  email: null,
  id: null,
  roles: null,
  token: null,
  username: null,
  tokenExpiration: null,
  isRefreshing: false,
};

export const Auth = types
  .model({
    email: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    roles: types.maybeNull(types.array(types.string)),
    token: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    tokenExpiration: types.maybeNull(types.number),
    isRefreshing: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get isLoggedIn() {
      if (!self.token) return false;
      if (self.tokenExpiration && self.tokenExpiration < Date.now())
        return false;
      return true;
    },
    hasRole(role: string) {
      const authRoles = JSON.parse(
        window?.localStorage?.getItem('seller-auth')
      )?.roles;
      return authRoles?.includes(role) ? true : false;
    },
  }))
  .actions((self) => ({
    login: flow(function* (data: LoginInfo) {
      const result: LoginResult = yield login(data);
      self.email = result.email;
      self.id = `${result.id}`;
      self.roles = cast(result.roles);
      self.token = result.token;
      result.token;
      self.username = result.username;
      const { exp } = jwtDecode<JwtPayload>(result.token);
      if (exp == null) throw 'No expiration found on JWT token';
      self.tokenExpiration = exp * 1000;
    }),
    clearToken() {
      self.token = null;
      self.tokenExpiration = null;
    },
    setIsRefresh(isRefreshing: boolean) {
      self.isRefreshing = isRefreshing;
    },
  }));
