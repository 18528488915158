import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/integrations/pims`;

const MOCK_RESPONSE = {
  'content': [
    {
      'uuid': '9cb7fb35-d41f-4b68-be78-5d271261d913',
      'url':
        'https://testsellersa.blob.core.windows.net/csv/boots-1651500015.580536.csv',
      'status': 'IN_REVIEW',
      'createdAt': '2022-05-02T14:00:49.392Z',
      'updatedAt': '2022-05-02T14:00:49.392Z',
    },
    {
      'uuid': 'e9ba1ad0-2b65-483e-a654-2c50476f78bf',
      'url':
        'https://testsellersa.blob.core.windows.net/csv/footlocker-1651500020.589394.csv',
      'status': 'IN_REVIEW',
      'createdAt': '2022-05-02T14:00:47.587Z',
      'updatedAt': '2022-05-02T14:00:47.587Z',
    },
    {
      'uuid': '7bcc5d78-1bcd-4857-bea2-e8dfcbdec324',
      'url':
        'https://testsellersa.blob.core.windows.net/csv/pottery_barn-1651500020.616224.csv',
      'status': 'IN_REVIEW',
      'createdAt': '2022-05-02T14:00:35.146Z',
      'updatedAt': '2022-05-02T14:00:35.147Z',
    },
  ],
  'pageable': {
    'sort': {
      'empty': false,
      'unsorted': false,
      'sorted': true,
    },
    'offset': 0,
    'pageNumber': 0,
    'pageSize': 10,
    'paged': true,
    'unpaged': false,
  },
  'totalPages': 1,
  'totalElements': 3,
  'last': false,
  'size': 10,
  'number': 0,
  'sort': {
    'empty': false,
    'unsorted': false,
    'sorted': true,
  },
  'numberOfElements': 3,
  'first': true,
  'empty': false,
};

export const integrationsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
