import React, { ReactElement, ReactNode } from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import Icon, { IconType } from 'components-shared/icon/Icon';
import { ThemeConfig } from 'styles/theme';

type ContainerProps = {
  backgroundColor: keyof ThemeConfig['colors'];
  icon?: IconType;
  children: ReactNode;
};

const IconFlex = styled(Flex)(({ theme: { space } }) => ({
  marginInlineEnd: space[3],
  maxWidth: '32px',
  minWidth: '32px',
  maxHeight: '32px',
  minHeight: '32px',
}));

export function ToastContainer(props: ContainerProps): ReactElement {
  const { children, backgroundColor, icon } = props;

  return (
    <Flex
      sx={{
        p: 3,
        color: 'white',
        alignItems: 'center',
        backgroundColor,
        fontSize: 'small',
        paddingInlineEnd: '46px',
      }}
    >
      {icon && (
        <IconFlex>
          <Icon name={icon} bold size={32} color="white" />
        </IconFlex>
      )}
      {children}
    </Flex>
  );
}
