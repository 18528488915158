// token used just for mocking generated by http://jwtbuilder.jamiekurtz.com/
// {
//   "iss": "Seller Lab",
//   "iat": 1651136049,
//   "exp": 1903596849,
//   "aud": "www.tamanna.com",
//   "sub": "seller-lab@tamanna.com"
// }
export const MOCK_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTZWxsZXIgTGFiIiwiaWF0IjoxNjUxMTM2MDQ5LCJleHAiOjE5MDM1OTY4NDksImF1ZCI6Ind3dy50YW1hbm5hLmNvbSIsInN1YiI6InNlbGxlci1sYWJAdGFtYW5uYS5jb20ifQ.FbzF67lAb0bNkF4UsNXcVwEnRgICRGJSSh2HAQOXys4';
