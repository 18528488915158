import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';
import { ImportDetails, ImportType } from 'models';
import { DATE_TIME_FORMAT } from 'constants/common';
import { Nullable } from 'models/utils';

type LastImportsState = {
  state: {
    inventory: ImportDetails | null;
    products: ImportDetails | null;
  };
  updateTime: string | null;
};

const initialState = {
  state: {
    inventory: null,
    products: null,
  },
  updateTime: null,
} as LastImportsState;

type ContextType = [LastImportsState, (importType: ImportType, state: Nullable<ImportDetails>, date?: string) => void];
const Context = createContext<ContextType>([initialState, () => null]);

const Provider: React.FC = (props) => {
  const [state, setState] = useState(initialState);

  const setLastImportState = (
    importType: ImportType,
    state: Nullable<ImportDetails>,
    date = dayjs().format(DATE_TIME_FORMAT)
  ) => {
    if (!state) return;
    setState((currentState) => ({
      state: {
        ...currentState.state,
        [importType]: state,
      },
      updateTime: date,
    }));
  };

  return <Context.Provider value={[state, setLastImportState]}>{props.children}</Context.Provider>;
};

export { Context as LastImportStateContext, Provider as LastImportStateProvider };
