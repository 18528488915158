import Link from 'next/link';
import React, { ReactElement } from 'react';
import { Flex, Link as RebassLink, Text } from 'rebass/styled-components';
import { ToastContainer } from './container';

export type InfoLinkNotificationProps = {
  message: string;
  linkMessage: string;
  href: string;
};

export function InfoLinkNotification(props: InfoLinkNotificationProps): ReactElement {
  const { message, href, linkMessage } = props;
  return (
    <ToastContainer backgroundColor="darkshaya60" icon="face_happy">
      <Flex flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Text>{message}</Text>
        <Link href={href} passHref>
          <RebassLink sx={{ textDecoration: 'underline', fontWeight: 'bold' }} mt={2} color="inherit" fontSize="tiny">
            {linkMessage}
          </RebassLink>
        </Link>
      </Flex>
    </ToastContainer>
  );
}
