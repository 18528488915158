import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { Icon } from '@tamo-marketplace/genie';
import LegacyButtonLink from 'components-shared/LegacyButtonLink';
import Link from 'components-shared/Link';
import { maxContentWidth } from 'styles/theme';
import Button from 'components-shared/ButtonWithIcon';

export const MenuWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;
export const MenuBox = styled(Box)`
  height: 100%;
  position: relative;
  max-width: ${maxContentWidth};
  margin: auto;
`;
export const MenuNavigationContent = styled(Flex)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export const MenuItem = styled(Flex)`
  height: 100%;
  justify-content: center;
  justify-content: flex-start;
`;
export const MenuItemWrapper = styled(Flex)`
  align-items: center;
`;
export const FirstLevelMenuContent = styled(Flex)(
  () => css`
    align-items: center;
  `
);

export const HomeLink = styled(Link)(({ theme: { colors } }) => ({
  display: 'flex',
  textDecoration: 'none',
  '& .header-logo': {
    opacity: 1,
    transition: '.15s opacity ease-in',
  },
  '& .header-title': {
    textTransform: 'uppercase',
    transition: '.15s color ease-in',
  },
  ':hover': {
    '& .header-title': {
      color: colors.neutral100,
    },
    '& .header-logo': {
      opacity: 0.7,
    },
  },
}));

export const NavLink = styled(LegacyButtonLink)(
  ({ theme: { colors, fontWeights, fontSizes, space } }) => ({
    color: colors.neutral120,
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    fontSize: fontSizes[14],
    fontWeight: fontWeights.regular,
    padding: `${space[6]} ${space[8]}`,
    marginRight: space[24],
    transition: '.4s background-color',
    '&:hover': {
      backgroundColor: colors.neutral40,
      cursor: 'pointer',
    },
    '&.active': {
      backgroundColor: colors.neutral40,
    },
  })
);

export const StyledTitle = styled(Text)(
  ({ theme: { colors, fontSizes, fontWeights, space } }) => ({
    color: colors.neutral120,
    fontSize: fontSizes[18],
    fontWeight: fontWeights.bold,
    marginLeft: space[16],
  })
);
export const Separator = styled(Box)(({ theme: { colors, space } }) => ({
  width: '1px',
  backgroundColor: colors.neutral60,
  height: '60px',
  marginRight: space[16],
  marginLeft: space[20],
}));

export const AvatarCircle = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  ${({ theme: { colors, space } }) => `
    background: ${colors.summer100};
    margin-right: ${space[8]};
`};
`;
export const Initials = styled.div(
  ({ theme: { colors, fontSizes, fontWeights, lineHeights } }) => css`
    position: relative;
    top: 11px;
    font-size: ${fontSizes[20]};
    font-weight: ${fontWeights.bold};
    line-height: ${lineHeights[1]};
    color: ${colors.neutral00};
  `
);
export const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;
`;
export const DropdownMenuContent = styled.div(
  ({ theme: { zIndices, colors, space } }) => css`
    margin-left: -65px;
    margin-top: 23px;
    display: none;
    border-top: 1px solid #e9e9e9;
    position: absolute;
    background-color: ${colors.neutral20};
    min-width: 149px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: ${space[14]} ${space[14]};
    z-index: ${zIndices.zIndex10};
  `
);

export const StyledButton = styled(Button)(
  ({ theme: { colors, fontSizes, fontWeights } }) => ({
    backgroundColor: 'transparent',
    outline: 'none',
    width: '100%',
    color: colors.neutral120,
    fontSize: fontSizes[14],
    fontWeight: fontWeights.regular,
    letterSpacing: '0.02em',
    cursor: 'pointer',
    padding: '6px 70px 6px 8px',
    transition: '.4s background-color',
    '&:hover': {
      backgroundColor: colors.neutral60,
      cursor: 'pointer',
    },
  })
);

export const ChevronIcon = styled(Icon)`
  margin-left: 12px;
  margin-top: 2px;
`;

export const UserContainer = styled(Flex)`
  cursor: pointer;
`;
