import { ReactElement, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export default function ClientReactPortal(props: { children: ReactElement; selector: string | Element }): ReactElement {
  const { children, selector } = props;
  const ref = useRef<Element>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (typeof selector === 'string') {
      ref.current = document.querySelector(selector);
    } else {
      ref.current = selector;
    }
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, document.body) : null;
}
