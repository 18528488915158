import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/vendors/30`;

const MOCK_RESPONSE = {
  'id': 30,
  'name': 'VaVaVoom',
  'tenantId': '5DF1363059675161A85F576D',
  'verticals': ['WOMEN', 'MEN', 'HOME', 'KIDS'],
};

export const vendorIdHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
