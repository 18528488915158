import { ImportType } from 'models';

export const ImageRoute = (filename: string): string => `/images/${filename}`;

export const HomeRoute = '/';
export const LoginRoute = '/auth/login';
export const ProductDetailsRoute = '/catalog';

export const getImportRoutes = (type: ImportType) => {
  const basePath = `/${type}`;
  return {
    basePath,
    Import: `${basePath}/import`,
    ImportHistory: `${basePath}/imports`,
    ImportDetails: (importId: string | number): string =>
      `${basePath}/imports/${importId}`,
  };
};

export const ImportRoutes = { Import: `/import` };

export const IntegrationRoutes = (() => {
  const basePath = '/integrations';
  return {
    basePath,
    PIMS: `${basePath}/pims`,
  };
})();

export const VendorRoutes = (() => {
  const basePath = '/vendors';
  return {
    basePath,
    Catalogs: `${basePath}`,
    CatalogIdQuery: 'catalogId',
    VendorDetails: (vendorId: string | number): string =>
      `${basePath}/${vendorId}`,
  };
})();

export const CategoriesRoutes = (() => {
  const basePath = '/categories';
  return {
    basePath,
    Categories: `${basePath}`,
  };
})();
