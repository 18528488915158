import React from 'react';
import { LinkProps } from 'rebass/styled-components';
import Button, { ButtonWithIconProps } from './ButtonWithIcon';
import Link from 'components-shared/Link';
import { WithTestId } from 'models/utils';

export type LegacyButtonLinkProps = WithTestId<{
  href?: string;
  containerProps?: LinkProps;
}> &
  ButtonWithIconProps;

const LegacyButtonLink: React.FC<LegacyButtonLinkProps> = ({
  children,
  href,
  disabled,
  containerProps = {},
  onClick,
  ...buttonProps
}) => {
  if (disabled || !href) {
    return (
      <Button disabled={disabled} onClick={onClick} {...buttonProps}>
        {children}
      </Button>
    );
  }
  return (
    <Link href={href} {...containerProps}>
      <Button disabled={disabled} {...buttonProps}>
        {children}
      </Button>
    </Link>
  );
};

export default LegacyButtonLink;
