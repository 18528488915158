import React, { ComponentType, FC, Provider } from 'react';

export type PropsOf<TComponentOrTProps> =
  TComponentOrTProps extends ComponentType<infer TProps> ? TProps : never;

export type ProviderType<T = ComponentType<any> | Provider<any>> =
  | T
  | [T, PropsOf<T>];

type Props = {
  components: ProviderType[];
  noChildComponents?: ProviderType[];
};

export const Compose: FC<Props> = ({
  components,
  children,
  noChildComponents,
}) => {
  const NoChildComponents = () => {
    if (!noChildComponents?.length) return null;
    return (
      <>
        {noChildComponents.map((curr, i) => {
          const [Provider, props] = Array.isArray(curr)
            ? [curr[0], curr[1]]
            : [curr, {}];
          return <Provider key={i} {...props} />;
        })}
      </>
    );
  };
  return (
    <>
      {components.reverse().reduce(
        (acc, curr) => {
          const [Provider, props] = Array.isArray(curr)
            ? [curr[0], curr[1]]
            : [curr, {}];
          return <Provider {...props}>{acc}</Provider>;
        },
        <>
          <NoChildComponents />
          {children}
        </>
      )}
    </>
  );
};
