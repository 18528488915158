import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, SxStyleProp } from 'rebass/styled-components';
import { Modify, StyleColor } from 'models/utils';
import { getStyleColorValue } from 'utils/color';

const DEFAULT_SIZE = 20;

/**
 * To quickly get icon names as a union, run the generateIconNameUnion script:
 * - node scripts/generateIconNameUnion.js
 */
type FontIcon =
  | 'alert'
  | 'arrow'
  | 'arrow_end'
  | 'arrow_end_round'
  | 'arrow_filter'
  | 'arrow_narrow_down'
  | 'arrow_round'
  | 'arrow_thin'
  | 'available_stock'
  | 'big_check'
  | 'checklist'
  | 'circular_check'
  | 'circular_error'
  | 'clock'
  | 'clock_filled'
  | 'close'
  | 'concierge'
  | 'cs_delivery'
  | 'cs_order'
  | 'cs_payments'
  | 'cs_size'
  | 'day_return'
  | 'download'
  | 'express'
  | 'face_happy'
  | 'face_neutral'
  | 'face_sad'
  | 'face_smile'
  | 'file'
  | 'file_analysing'
  | 'file_download'
  | 'file_errors'
  | 'file_errors_small'
  | 'file_filled'
  | 'file_success'
  | 'file_upload'
  | 'filter'
  | 'free_delivery'
  | 'heart'
  | 'heart_filled'
  | 'info'
  | 'lock'
  | 'orders'
  | 'payments'
  | 'preferences'
  | 'puzzle'
  | 'search'
  | 'shipping'
  | 'shopping_bag'
  | 'shopping_bag_full'
  | 'success'
  | 'trash'
  | 'upload'
  | 'user'
  | 'user_filled'
  | 'user_thin'
  | 'warning'
  | 'zoom';

export type IconType = FontIcon;

interface FontProps {
  size?: number | string;
  color?: StyleColor | [StyleColor, StyleColor];
  bold?: boolean;
}

interface RotativeBoxProps {
  rotateDeg?: number;
}

export interface IconProps extends FontProps, RotativeBoxProps {
  name: IconType;
  title?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => void;
  margin?: number | string;
  noFlip?: boolean;
  style?: SxStyleProp;
}

const Font = styled.i<Modify<FontProps, { color?: StyleColor; secondaryColor?: StyleColor }>>(
  ({ size = DEFAULT_SIZE, color, secondaryColor, bold = false, theme: { fontWeights } }) => css`
    font-size: ${size}px;
    font-weight: ${bold ? fontWeights.bold : fontWeights.regular};
    ${secondaryColor ? `--icon-secondary: ${getStyleColorValue(secondaryColor)};` : ''}
    ${color ? `&:before { color: ${getStyleColorValue(color)};` : ''}
  `
);

const RotativeBox = styled(Flex)<RotativeBoxProps>`
  ${({ rotateDeg = 0 }) => `
    position: relative;
    align-items: center;
    justify-content: center;
    transform: rotate(${rotateDeg}deg);
  `}
`;

const RootBox = styled(Box)<{ onClick?: () => void }>`
  ${({ onClick }) => `
    &:hover {
      cursor: ${onClick ? 'pointer' : 'inherit'};
    }
  `}
`;

const IconElement = ({ name, title, color, size, ...props }: FontProps & Pick<IconProps, 'name' | 'title'>) => {
  const [primary, secondary] = (Array.isArray(color) && color.length > 1 ? color : [color, color]) as StyleColor[];
  return (
    <>
      <Font
        className={`icon icon-${name}`}
        size={size}
        aria-hidden="true"
        title={title}
        {...(color
          ? {
              color: primary,
              secondaryColor: secondary,
            }
          : {})}
        data-testid="font-icon"
        {...props}
      />
      <span className="sr-only">{name}</span>
    </>
  );
};

const Icon = ({ name, title, rotateDeg, margin, onClick, noFlip, style, color, ...props }: IconProps) => (
  <RootBox onClick={onClick} margin={margin} className={noFlip ? '' : 'flip-icon'} sx={style} color={color}>
    <RotativeBox rotateDeg={rotateDeg}>
      <IconElement name={name} title={title} {...props} />
    </RotativeBox>
  </RootBox>
);

export default Icon;
