import 'normalize.css';
import 'styles/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'utils/tracer-init';

import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import defaultLayout from 'layouts/default';
import ProtectRoutes from 'components/auth/ProtectedRoutes';
import { AppContext } from 'contexts/pages/app';
import {
  ComponentWithCustomLayoutProps,
  NextComponentWithCustomLayout,
} from 'models/next/screens';
import withInitializedStore from 'store/withInitializedStore';

dayjs.extend(relativeTime);

// Enabling msw for local development
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../mocks');
}

const TamannaApp = (props: ComponentWithCustomLayoutProps) => {
  const { Component, pageProps } = props;
  // if no customLayout is provided is the page, then uses the globalLayout
  const Layout: NextComponentWithCustomLayout['customLayout'] =
    Component.customLayout || defaultLayout;

  return (
    <AppContext appProps={props}>
      <ProtectRoutes DefaultLayout={Layout}>
        <Component {...pageProps} />
      </ProtectRoutes>
    </AppContext>
  );
};

TamannaApp.getInitialProps = async () => {
  return {};
};

export default withInitializedStore(TamannaApp);
