import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { Link as RebassLink, LinkProps as RebassLinkProps } from 'rebass/styled-components';
import styled from 'styled-components';

type LinkProps = RebassLinkProps & {
  href: string;
  nextLinkProps?: Omit<NextLinkProps, 'href'>;
  withHoverFeedback?: boolean;
};

const StyledRebassLink = styled(RebassLink)<Pick<LinkProps, 'withHoverFeedback'>>(({ withHoverFeedback }) => ({
  opacity: 1,
  ...(withHoverFeedback && {
    transition: 'opacity 0.3s ease',
    ':hover': {
      opacity: '0.7',
    },
  }),
}));

const Link = (props: LinkProps) => {
  const { href, children, nextLinkProps, withHoverFeedback = false, ...rebassProps } = props;
  return (
    <NextLink href={href} passHref {...nextLinkProps}>
      <StyledRebassLink withHoverFeedback={withHoverFeedback} {...rebassProps}>
        {children}
      </StyledRebassLink>
    </NextLink>
  );
};

export default Link;
