import { rest } from 'msw';
import { MOCK_TOKEN } from '../mockToken';
import {
  ROLE_BRAND_ACQUISITION,
  ROLE_VENDOR_MANAGER,
} from '../../constants/common';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/auth/signin`;

const MOCK_VENDOR_MANAGER = {
  email: 'dev_vendor_manager@tamanna.com',
  id: '1',
  roles: [ROLE_VENDOR_MANAGER],
  token: MOCK_TOKEN,
  username: 'Dev_VendorManager',
  tokenExpiration: 9999,
  isRefreshing: false,
};

const MOCK_BRAND_ACQUISITION = {
  email: 'dev_brand_acquisition@tamanna.com',
  id: '1',
  roles: [ROLE_BRAND_ACQUISITION],
  token: MOCK_TOKEN,
  username: 'Dev_BrandAcquisition',
  tokenExpiration: 9999,
  isRefreshing: false,
};

const AUTHENTICATED_USER =
  process.env.NEXT_PUBLIC_AUTH_MOCKING_BA === 'enabled'
    ? MOCK_BRAND_ACQUISITION
    : MOCK_VENDOR_MANAGER;

export const loginHandler = rest.post(URL, (req, res, ctx) => {
  // Persist user's authentication in the session
  localStorage.setItem('seller-auth', JSON.stringify(AUTHENTICATED_USER));

  return res(
    ctx.status(200),
    ctx.json({
      'token': AUTHENTICATED_USER.token,
      'id': AUTHENTICATED_USER.id,
      'username': AUTHENTICATED_USER.username,
      'email': AUTHENTICATED_USER.email,
      'roles': AUTHENTICATED_USER.roles,
    })
  );
});
