import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/products/MTk3MjM/overview`;

const MOCK_RESPONSE = {
  'vendorName': 'Jack Wills',
  'vendorRef': 'JAC',
  'brandName': 'Jack Wills',
  'productName': 'Pullborough 2 Pack Socks',
  'sku': '123456789000',
  'categories': ['Women', 'Men', 'Kids'],
  'colours': ['Green', 'Red', 'Blue'],
  'sizes': ['M', 'S'],
  'prices': [
    {
      'min': 10.0,
      'max': 20.0,
      'currency': 'KWD',
    },
  ],
};

export const productOverviewHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
