/* eslint-disable prefer-destructuring */
/* eslint-disable no-magic-numbers */

export const maxContentWidth = '1920px';

export const maxGridWidth = '1140px';

export const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
} as const;

const up = (key: keyof typeof screens): string => {
  return `@media (min-width:${screens[key]})`;
};

const fontSizes = [12, 14, 16, 18, 24, 32, 48, 72];

const breakpoints = [screens.sm, screens.md, screens.lg];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
breakpoints.sm = breakpoints[0];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
breakpoints.md = breakpoints[1];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
breakpoints.lg = breakpoints[2];

const theme = {
  breakpoints,
  screens,
  up,
  fontSizes: {
    ...fontSizes,
    tiny: fontSizes[0], // 12
    small: fontSizes[1], // 14
    normal: fontSizes[2], // 16
    subTitle: fontSizes[3], // 18
    header: fontSizes[4], // 24
    title: fontSizes[5], // 32
    chunky: fontSizes[6], // 48
    huge: fontSizes[7], // 72
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    darkgrey: '#272727',
    darkshaya60: '#606060',
    darkshaya40: '#9A9A9A',
    darkshaya30: '#CCCCCC',
    darkshaya20: '#DDDDDD',
    darkshaya10: '#F4F4F4',
    pink: 'rgba(225, 68, 68, 0.2)',
    red: '#B93636',
    redDark: '#820010',
    warningBee: '#BE560C',
    alshayaSummerDarker: '#1e787a',
    alshayaSummerDark: '#269699',
    alshayaSummer: '#59C5C8',
    alshayaSummer80: '#7ad1d3',
    alshayaSummer50: '#ace2e4',
    alshayaSummer20: '#def3f4',
    alshayaSummer10: '#eef9fa',
    blue: '#42A5F5',
    blue50: '#a1d2fa',
    blue20: '#d9edfd',
    blue10: '#ecf6fe',
    darkblue: '#3584c4',
    darkerblue: '#286393',
    infoWinter: '#5C779A',
    lightYellow: '#fff9df',
    darkYellow: '#ccc7b2',
    darkerYellow: '#666459',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 100,
    regular: 400,
    bold: 700,
  },
  lineHeights: {
    body: 'normal',
    heading: 1.25,
    lineHeight0: '0',
    lineHeight1: '1',
    lineHeight110: '1.1',
    lineHeight140: '1.4',
    lineHeight150: '1.5',
    lineHeight170: '1.7',
    lineHeight200: '2',
  },
  zIndex: {
    content: 0,
    contentDimmer: 9,
    header: 10,
    globalDimmer: 11,
    modal: 12,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    link: {
      textDecoration: 'none',
      color: 'darkgrey',
    },
    underlineLink: {
      variant: 'text.underline',
    },
  },
  text: {
    default: {
      color: 'darkgrey',
    },
    secondary: {
      color: 'darkshaya40',
      fontSize: '12px',
      display: 'inline-block',
    },
    caps: {
      variant: 'text.default',
      textTransform: 'uppercase',
    },
    heading: {
      fontSize: 'title',
      color: 'darkgrey',
    },
    underline: {
      borderColor: 'grey',
      borderStyle: 'solid',
      borderWidth: '0px 0px 1px 0px',
      paddingBottom: '4px',
      textTransform: 'uppercase',
      ':hover': { cursor: 'pointer' },
      fontWeight: 'bold',
    },
    pageTitle: {
      variant: 'text.default',
      fontSize: '30px',
      fontWeight: 'bold',
    },
    importStatusTile: {
      variant: 'text.default',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    formError: {
      fontSize: 'small',
      color: 'red',
    },
    loginTitle: {
      variant: 'text.default',
      fontSize: ['32px', '36px'],
    },
    loginText: {
      variant: 'text.default',
      fontSize: ['16px', '18px'],
    },
  },
  buttons: {
    primary: {
      bg: 'darkgrey',
      height: ['40px', '48px'],
      textTransform: 'uppercase',
      transition: 'all .3s ease',
      cursor: 'pointer',
      '&:hover': {
        bg: 'darkshaya60',
      },
      '&:disabled': {
        bg: 'darkshaya40',
        cursor: 'default',
      },
      borderRadius: '8px',
      '& .loading-container': {
        bg: 'darkgrey',
        borderRadius: '8px',
      },
    },
    primarySmall: {
      variant: 'buttons.primary',
      fontSize: 'small',
      height: ['40px', '40px'], // needs to be like this
      minWidth: '115px',
    },
    primaryError: {
      variant: 'buttons.primary',
      bg: 'red',
      '&:hover': {
        cursor: 'pointer',
        bg: 'redDark',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'white',
      fontWeight: 'bold',
      border: '1px solid',
      borderColor: 'darkgrey',
      color: 'darkgrey',
      '&:hover:enabled': {
        cursor: 'pointer',
        bg: '#f1f1f1',
      },
      '&:disabled': {
        color: '#b5b5b5',
        border: '1.5px solid',
        borderColor: 'darkshaya20',
        cursor: 'default',
      },
    },
    secondarySmall: {
      variant: 'buttons.secondary',
      fontSize: 'small',
      height: ['40px', '40px'], // needs to be like this
      minWidth: '115px',
    },
    small: {
      bg: 'darkgrey',
      textTransform: 'uppercase',
      transition: '300ms all',
      borderRadius: '8px',
      '&:hover': {
        cursor: 'pointer',
        bg: 'darkshaya60',
      },
      '&:disabled': {
        bg: 'darkshaya20',
        cursor: 'default',
      },
    },
    borderless: {
      color: 'darkgrey',
      bg: 'transparent',
      outline: 'none',
      padding: 0,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    textLink: {
      color: 'darkgrey',
      bg: 'transparent',
      outline: 'none',
      padding: 0,
      borderRadius: 0,
      paddingBottom: '4px',
      fontWeight: 500,
      borderBottom: '1px solid darkgrey',
      '&:hover': {
        cursor: 'pointer',
        borderBottom: '1px solid black',
      },
    },
    iconRound: {
      color: 'darkgrey',
      bg: 'transparent',
      outline: 'none',
      borderRadius: '50%',
      padding: '8px',
      transition: '300ms all',
      '&:hover': {
        bg: 'darkshaya10',
        cursor: 'pointer',
      },
      '&:active': {
        bg: 'darkshaya20',
      },
      '&:disabled': {
        color: 'darkshaya20',
        cursor: 'default',
      },
    },
    iconRoundSmall: {
      variant: 'buttons.iconRound',
      padding: '4px',
    },
    pagination: {
      color: 'darkgrey',
      bg: 'transparent',
      padding: '8px',
      border: '1px solid',
      borderColor: 'darkgrey',
      borderRadius: '8px',
      transition: '300ms background-color',
      '&:hover:not(:disabled)': {
        cursor: 'pointer',
        bg: 'darkshaya10',
      },
      '&:disabled': {
        cursor: 'default',
        color: 'darkshaya30',
        borderColor: 'darkshaya30',
      },
    },
    navButton: {
      variant: 'buttons.borderless',
      textTransform: 'uppercase',
      color: 'darkgrey',
      fontSize: 'small',
      fontWeight: 'regular',
      letterSpacing: '0.02em',
      cursor: 'pointer',
      px: '8px',
      py: '6px',
      transition: '.4s background-color',
      '&:hover': {
        backgroundColor: 'darkshaya20',
      },
    },
  },
  forms: {
    select: {
      borderRadius: '6px',
      border: '1px solid',
      borderColor: 'darkgrey',
      height: ['40px', '48px'],
      paddingX: '16px',
      fontFamily: 'alshaya-group, spinnaker, sans-serif',
      '&:disabled': {
        backgroundColor: 'darkshaya10',
      },

      '&:invalid,& option[value=""]': {
        color: 'darkshaya40',
      },
    },
    selectSmall: {
      borderRadius: '6px',
      border: '1px solid',
      borderColor: 'darkgrey',
      padding: '8px',
      minWidth: '60px',
      fontFamily: 'alshaya-group, spinnaker, sans-serif',
      '&:disabled': {
        backgroundColor: 'darkshaya10',
      },
      '&:invalid,& option[value=""]': {
        color: 'darkshaya40',
      },
    },
    input: {
      borderRadius: '6px',
      border: '1px solid',
      borderColor: 'darkgrey',
      height: ['40px', '48px'],
      px: '16px',
      fontSize: 'small',
      '&:disabled': {
        backgroundColor: 'darkshaya10',
        borderColor: 'grey',
      },
    },
    label: {
      fontSize: '1',
      color: 'darkgrey',

      '& > div': {
        minWidth: 'auto',
      },
    },
    inputWithFloatingLabel: {
      variant: 'forms.input',
      '&::placeholder': {
        opacity: '0',
      },
      '&:not(:placeholder-shown) + label, &:focus + label': {
        cursor: 'default',
        fontSize: '12px',
        color: 'darkshaya40',
        transform: ['translate3d(0, -150%, 0)', 'translate3d(0, -180%, 0)'],
        backgroundColor: 'white !important',
      },
      '&:focus': {
        outline: 'none !important',
        border: '2px solid',
        borderColor: 'alshayaSummer',
      },
    },
    searchInput: {
      color: 'darkgrey',
      border: 'none !important',
      fontSize: ['small', 'tiny'],
      '&::placeholder': {
        color: 'darkshaya40',
      },
      '&:focus ': {
        outline: 'none',
      },
    },
    floatingLabel: {
      position: 'absolute',
      transition: '200ms all',
      left: '8px',
      padding: '0 3px',
      pointerEvents: 'none',
      width: 'auto',
      color: 'darkshaya40',
      borderRadius: '2px',
    },
    floatingLabelTextArea: {
      position: 'relative',
      top: '-178px', // This position works for TextArea with 200px of height
      height: '18px',
      width: 'fit-content',
      transition: '200ms all',
      left: '8px',
      padding: '0 8px',
      pointerEvents: 'none',
      color: 'darkshaya40',
    },
  },
};

export const { colors, zIndex } = theme;
export type ThemeConfig = typeof theme;

export default theme;
