import { ToastOptions, toast } from 'react-toastify';
import React, { ReactElement } from 'react';
import { InfoNotification, InfoNotificationProps } from './info';
import { InfoLinkNotification, InfoLinkNotificationProps } from './info-link';
import { ErrorNotification, ErrorNotificationProps } from './error';

export const AUTO_CLOSE_TIMEOUT_SEC = 10000;

type Options = 'info' | 'info-link' | 'error';

type NotificationType<Type extends Options, Props> = [Type, Props];

type NotificationPropTypes =
  | NotificationType<'info', InfoNotificationProps>
  | NotificationType<'info-link', InfoLinkNotificationProps>
  | NotificationType<'error', ErrorNotificationProps>;

const defaultOptions: ToastOptions = {
  autoClose: AUTO_CLOSE_TIMEOUT_SEC,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: true,
};

const renderToast = <T extends Record<string, unknown>>(
  Component: (props: T) => ReactElement,
  props: T,
  options: ToastOptions = {}
) =>
  toast((toastProps) => <Component {...toastProps} {...props} />, {
    ...defaultOptions,
    ...options,
  });

export const displayNotification: (...args: NotificationPropTypes) => void = (
  type,
  props
): void => {
  switch (type) {
    case 'info':
      renderToast(InfoNotification, props as InfoNotificationProps);
      break;

    case 'info-link':
      renderToast(InfoLinkNotification, props as InfoLinkNotificationProps);
      break;

    case 'error':
      renderToast(ErrorNotification, props as ErrorNotificationProps);
      break;

    default:
      throw Error(`Notification type "${type}" is not implemented!`);
  }
};
