import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import DesktopMenu from './menu/DesktopMenu';
import { useStore } from 'store';
import { LoginRoute } from 'constants/routes';
import { maxGridWidth } from 'styles/theme';

export const HEADER_HEIGHT_DESKTOP = '64px';

const NavBarContainer = styled.header(
  ({ theme: { colors, zIndices, space } }) => css`
    position: sticky;
    top: 0;
    z-index: ${zIndices.zIndex10};
    height: ${HEADER_HEIGHT_DESKTOP};
    border-bottom: 1px solid ${colors.neutral40};
    background-color: ${colors.neutral00};
    display: flex;
    justify-content: center;
    div {
      max-width: ${maxGridWidth};
    }
    padding-left: ${space[20]};
    padding-right: ${space[20]};
  `
);

const Header = () => {
  const {
    auth: { hasRole, username },
    logout: performLogout,
  } = useStore();
  const { push: pushToRoute } = useRouter();

  const logout = useCallback(() => {
    performLogout();
    pushToRoute(LoginRoute);
  }, [performLogout, pushToRoute]);

  return (
    <NavBarContainer>
      <DesktopMenu
        handleLogout={logout}
        hasRole={hasRole}
        username={username}
      />
    </NavBarContainer>
  );
};

export default Header;
