import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Header, { HEADER_HEIGHT_DESKTOP } from './header';
import { CustomLayoutProps } from 'models/next/screens';

// https://github.com/vercel/next.js/issues/18003
// To suppress Warning: useLayoutEffect does nothing on the server
// React.useLayoutEffect = React.useEffect;

const Grid = styled.div`
  display: grid;
  grid-template-rows: ${HEADER_HEIGHT_DESKTOP} auto;
  grid-template-columns: auto;
  min-height: 100vh;
  width: 100%;
`;

const Layout = (props: CustomLayoutProps): ReactElement => {
  const { children } = props;
  return (
    <Grid>
      <Header />
      {children}
    </Grid>
  );
};

export default Layout;
