import { rest } from 'msw';

const URL = `${process.env.NEXT_PUBLIC_API_URL}/catalogs`;

const MOCK_RESPONSE = [
  {
    'id': 'BAT',
    'vendor': {
      'id': 10,
      'name': 'Bath & Body Works',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'MOT',
    'vendor': {
      'id': 11,
      'name': 'Mothercare',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'FOO',
    'vendor': {
      'id': 12,
      'name': 'Foot Locker',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'AME',
    'vendor': {
      'id': 13,
      'name': 'American Eagle',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'DEB',
    'vendor': {
      'id': 14,
      'name': 'Debenhams',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'JAC',
    'vendor': {
      'id': 15,
      'name': 'Jack Wills',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'VIF',
    'vendor': {
      'id': 16,
      'name': "Victoria's Secret",
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'MIL',
    'vendor': {
      'id': 17,
      'name': 'Milano',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'BOD',
    'vendor': {
      'id': 18,
      'name': 'The Body Shop',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'NYX',
    'vendor': {
      'id': 19,
      'name': 'NYX Professional Makeup',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'SOL',
    'vendor': {
      'id': 21,
      'name': 'Solaris',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'CLA',
    'vendor': {
      'id': 22,
      'name': "Claire's",
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'SBX',
    'vendor': {
      'id': 23,
      'name': 'Starbucks',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'DRV',
    'vendor': {
      'id': 24,
      'name': 'Dr. Vranjes',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'catalog',
    'vendor': {
      'id': 25,
      'name': 'brand',
      'tenantId': 'tenantId',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'RIV',
    'vendor': {
      'id': 26,
      'name': 'River Island',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'CBD',
    'vendor': {
      'id': 27,
      'name': 'Debenhams Cosmetics',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'FDB',
    'vendor': {
      'id': 28,
      'name': 'Debenhams Fashion',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'MUJ',
    'vendor': {
      'id': 29,
      'name': 'Muji',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
  {
    'id': 'VVV',
    'vendor': {
      'id': 30,
      'name': 'VaVaVoom',
      'tenantId': '5DF1363059675161A85F576D',
      'catalogs': null,
      'brandList': null,
    },
  },
];

export const catalogsHandler = rest.get(URL, (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(MOCK_RESPONSE));
});
