import React from 'react';
import Head from 'next/head';
import { BASE_DESCRIPTION, BASE_TITLE } from 'constants/common';

const CustomHead = () => (
  <Head>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width,minimum-scale=1,maximum-scale=1,user-scalable=no" />
    <title>{BASE_TITLE}</title>
    <meta name="description" content={BASE_DESCRIPTION} />
    <link rel="manifest" href="/manifest.json" />
    <link rel="icon" href="/icons/favicon-16x16.png" type="image/png" sizes="16x16" />
    <link rel="icon" href="/icons/favicon-32x32.png" type="image/png" sizes="32x32" />
    <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
    <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="black" />
    <meta name="theme-color" content="#272727" />
  </Head>
);

export default CustomHead;
