import {
  CategoriesRoutes,
  HomeRoute,
  IntegrationRoutes,
  VendorRoutes,
} from 'constants/routes';
import { ROLE_BRAND_ACQUISITION, ROLE_VENDOR_MANAGER } from 'constants/common';

type PathName = 'Vendors' | 'Categories' | 'Catalog' | 'Integrations';
type PathOption = {
  [k in PathName]: { basePath: string; queryPath?: string };
};

export type PathOptions = Partial<PathOption>;

export const BRAND_ACQUISITION_OPTIONS = {
  'Vendors': {
    basePath: VendorRoutes.basePath,
    queryPath: VendorRoutes.CatalogIdQuery,
  },
  'Categories': {
    basePath: CategoriesRoutes.basePath,
  },
};

export const VENDOR_MANAGER_OPTIONS = {
  'Catalog': {
    basePath: HomeRoute,
  },
};

export const buildPathOptions = (
  hasRole: (role: string) => boolean
): PathOptions => {
  const options = hasRole(ROLE_BRAND_ACQUISITION)
    ? BRAND_ACQUISITION_OPTIONS
    : hasRole(ROLE_VENDOR_MANAGER)
    ? VENDOR_MANAGER_OPTIONS
    : {};

  return {
    ...options,
    'Integrations': {
      basePath: IntegrationRoutes.PIMS,
    },
  };
};
