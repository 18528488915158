import React, { ComponentType, useEffect, useState } from 'react';
import { IStore, initializeStore } from 'store';

function withInitializedStore<P>(Component: ComponentType<P>): ComponentType<P> {
  const WithInitializedStore = (props: P) => {
    const [store, setStore] = useState<IStore | null>(null);

    useEffect(() => {
      (async () => setStore(await initializeStore()))();
    }, []);

    return (store && <Component {...props} store={store} />) || null;
  };

  WithInitializedStore.displayName = `withInitializedStore(${Component.displayName || Component.name || 'Component'})`;
  return WithInitializedStore;
}

export default withInitializedStore;
