import React from 'react';
import {
  ButtonProps,
  Flex,
  FlexProps,
  Button as RebassButton,
  Text,
  TextProps,
} from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import Icon, { IconProps, IconType } from 'components-shared/icon/Icon';
import Spinner from 'components-shared/Spinner';
import { StyleColor } from 'models/utils';

export interface ButtonWithIconProps extends ButtonProps {
  label?: string;
  icon?: IconType;
  loading?: boolean;
  reverse?: boolean;
  labelProps?: TextProps;
  iconProps?: IconProps;
  contentContainerProps?: FlexProps;
}

const StyledRebassButton = styled(RebassButton)`
  position: relative;
`;

const Container = styled(Flex)<{ hasIcon: boolean }>(
  ({ hasIcon, theme: { up } }) => css`
    justify-content: ${hasIcon ? 'space-between' : 'center'};
    ${up('sm')} {
      justify-content: 'space-between';
    }
  `
);

const IconContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Button: React.FC<ButtonWithIconProps> = ({
  children,
  label,
  icon,
  loading,
  disabled,
  reverse,
  labelProps,
  iconProps,
  contentContainerProps = {},
  ...buttonProps
}) => {
  const {
    theme: { colors, space },
  } = useTheme();

  return (
    <StyledRebassButton disabled={disabled || loading} {...buttonProps}>
      {label || icon ? (
        <Container
          hasIcon={!!(icon || iconProps)}
          flexDirection={reverse ? 'row-reverse' : 'row'}
          alignItems="center"
          {...contentContainerProps}
        >
          <Text
            {...(icon || iconProps
              ? reverse
                ? { pl: space[4] }
                : { pr: space[4] }
              : {})}
            {...labelProps}
          >
            {label}
          </Text>
          {(icon || iconProps) && (
            <Icon
              name={(iconProps?.name || icon) as IconType}
              size={iconProps?.size ?? 20}
              {...iconProps}
            />
          )}
        </Container>
      ) : (
        children
      )}
      <AnimatePresence>
        {loading && (
          <IconContainer
            className="loading-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Spinner
              relative
              size={iconProps?.size ?? 24}
              color={colors.neutral00 as StyleColor}
            />
          </IconContainer>
        )}
      </AnimatePresence>
    </StyledRebassButton>
  );
};

export default Button;
